"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = configurePasswordServices;
var _requestPassword = _interopRequireDefault(require("./request-password"));
var _startSsoWorkflow = _interopRequireDefault(require("./start-sso-workflow"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function configurePasswordServices() {
  return [(0, _requestPassword.default)(), (0, _startSsoWorkflow.default)()];
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(configurePasswordServices, "configurePasswordServices", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/login-services/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();