"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merge = _interopRequireDefault(require("lodash/merge"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var DEFAULT_MOCK_SERVICES = {};

/* These are the default mock service configurations.
 * enabled - When set to true will enable ALL mocks.
 * include - a list of service names which will ALWAYS be mocked, even if enabled is false.
 * exclude - a list of service names which will NEVER be mocked, even is enabled is true.
 * immediate - When set to false, ALL service request will prompt the user one how to proceed. Meant for DEBUG purposes. This does not require mocks to be enabled.
 */
var _default = function _default(_ref) {
  var _ref$enabled = _ref.enabled,
    enabled = _ref$enabled === void 0 ? false : _ref$enabled,
    _ref$include = _ref.include,
    include = _ref$include === void 0 ? [] : _ref$include,
    _ref$exclude = _ref.exclude,
    exclude = _ref$exclude === void 0 ? [] : _ref$exclude,
    _ref$immediate = _ref.immediate,
    immediate = _ref$immediate === void 0 ? true : _ref$immediate,
    _ref$services = _ref.services,
    services = _ref$services === void 0 ? {} : _ref$services;
  return {
    enabled: enabled,
    include: include,
    exclude: exclude,
    immediate: immediate,
    services: (0, _merge.default)(DEFAULT_MOCK_SERVICES, services)
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(DEFAULT_MOCK_SERVICES, "DEFAULT_MOCK_SERVICES", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/mock/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/mock/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();