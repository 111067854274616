"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _isNil = _interopRequireDefault(require("lodash/isNil"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function onSuccess(content, callContext) {
  var redirect = callContext?.response?.data?.redirect;
  if ((0, _isNil.default)(redirect)) {
    throw new Error('Redirect not provided.');
  }
  window.location = redirect;
}
function onFailed(context, _ref) {
  var error = _ref.error;
  context.dispatch('SET_SESSION_FAILURE', error);
}
var _default = function _default() {
  return {
    serviceName: 'startSSOWorkflow',
    config: {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/common/v1/sso/{tenantId}/{accountNumber}/startSSOWorkflow',
      maxRedirects: 0
    },
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/login-services/start-sso-workflow.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/login-services/start-sso-workflow.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/login-services/start-sso-workflow.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();