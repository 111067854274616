"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _split = _interopRequireDefault(require("lodash/split"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function beforeRequest(context) {
  context.dispatch('SET_REQUIREMENTS_STATUS', [null, true]);
}
function onSuccess(context) {
  context.service.getSession();
  var _context$getStore = context.getStore('ApplicationContext'),
    l10n = _context$getStore.l10n;
  context.dispatch('SET_PASSWORD_MESSAGE', l10n('dashboard.password.change.success'));
  context.dispatch('RESET_REQUIREMENTS');
}
function onFailed(context, _ref) {
  var response = _ref.response;
  var _context$getStore2 = context.getStore('ApplicationContext'),
    l10n = _context$getStore2.l10n;
  var issues = [];
  if (response.status && response.status >= 400 && response.status < 500 && response.data) {
    var responseParts = (0, _split.default)(response.data, '"');
    if (responseParts && responseParts.length > 0) {
      issues = (0, _split.default)(responseParts[1], '|');
    } else {
      issues = [l10n('dashboard.password.change.error')];
    }
  } else {
    issues = [l10n('dashboard.password.change.error')];
  }
  context.dispatch('SET_REQUIREMENTS_STATUS', [issues, false]);
}
var _default = function _default() {
  return {
    serviceName: 'resolveResetPassword',
    config: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/common/v2/requirements/reset-password'
    },
    beforeRequest: beforeRequest,
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(beforeRequest, "beforeRequest", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-reset-password.js");
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-reset-password.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-reset-password.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-reset-password.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();