"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = configureRequirementServices;
var _retrieveAvailableAgreements = _interopRequireDefault(require("./retrieve-available-agreements"));
var _retrieveAvailableQuestions = _interopRequireDefault(require("./retrieve-available-questions"));
var _retrieveSelectedQuestions = _interopRequireDefault(require("./retrieve-selected-questions"));
var _answerSecurityQuestion = _interopRequireDefault(require("./answer-security-question"));
var _resolveCreatePassword = _interopRequireDefault(require("./resolve-create-password"));
var _resolveResetPassword = _interopRequireDefault(require("./resolve-reset-password"));
var _resolveSetSecurityQuestions = _interopRequireDefault(require("./resolve-set-security-questions"));
var _resolveEula = _interopRequireDefault(require("./resolve-eula"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function configureRequirementServices() {
  return [(0, _retrieveAvailableAgreements.default)(), (0, _retrieveAvailableQuestions.default)(), (0, _retrieveSelectedQuestions.default)(), (0, _answerSecurityQuestion.default)(), (0, _resolveCreatePassword.default)(), (0, _resolveResetPassword.default)(), (0, _resolveSetSecurityQuestions.default)(), (0, _resolveEula.default)()];
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(configureRequirementServices, "configureRequirementServices", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();