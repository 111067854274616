"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearSessionFailure = clearSessionFailure;
exports.setLoginMode = setLoginMode;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function clearSessionFailure(actionContext) {
  actionContext.dispatch('CLEAR_SESSION_FAILURE');
}
function setLoginMode(actionContext, mode) {
  actionContext.dispatch('SET_LOGIN_MODE', mode);
}
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(clearSessionFailure, "clearSessionFailure", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/actions/login-actions.js");
  reactHotLoader.register(setLoginMode, "setLoginMode", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/actions/login-actions.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();