"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localization = _interopRequireDefault(require("../../localization"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var _default = {
  version: '0.0.1',
  schema: '0.0.1',
  locale: 'en-US',
  configuration: {
    dashboard: {
      password: {
        change: {
          error: 'Something went wrong. Please try again or contact your administrator.',
          success: 'Your password has been updated successfully!'
        },
        emailSent: 'If this account is active you will receive an email to reset your password.'
      },
      questions: {
        incorrect: 'The answer you have entered is incorrect. Please try again.',
        maxAttempts: 'Max attempts reached to enter your security question. Please contact your administrator.',
        headingText: 'Please answer the following Security Question:',
        error: 'Something went wrong. Please try again or contact your administrator.'
      }
    },
    client: _localization.default
  }
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/config/application-context/application-localization.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();