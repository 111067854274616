"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function onSuccess(context) {
  context.dispatch('RECORD_CHALLENGE_ATTEMPT', [null]);
}
function onFailed(context, serviceContext) {
  context.dispatch('RECORD_CHALLENGE_ATTEMPT', [serviceContext.error]);
}
var _default = function _default() {
  return {
    serviceName: 'answerSecurityQuestion',
    config: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/common/v2/securityQuestions/challenge'
    },
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/answer-security-question.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/answer-security-question.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/answer-security-question.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();