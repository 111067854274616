"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _requirementIds = _interopRequireDefault(require("../requirement-ids"));
var _createPassword = _interopRequireDefault(require("./create-password"));
var _eula = _interopRequireDefault(require("./eula"));
var _resetPassword = _interopRequireDefault(require("./reset-password"));
var _setSecurityQuestions = _interopRequireDefault(require("./set-security-questions"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var requirementPages = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, _requirementIds.default.createPassword, _createPassword.default), _requirementIds.default.eula, _eula.default), _requirementIds.default.resetPassword, _resetPassword.default), _requirementIds.default.setSecurityQuestions, _setSecurityQuestions.default);
var _default = requirementPages;
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(requirementPages, "requirementPages", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/components/requirements/routes/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/components/requirements/routes/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();