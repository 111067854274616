"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reduce = _interopRequireDefault(require("lodash/reduce"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function beforeRequest(context, requestContext) {
  context.dispatch('SET_REQUIREMENTS_STATUS', [null, true]);
  var sessionStore = context.getStore('Session');
  var authMethod = sessionStore.getAuthMethod;
  var data = (0, _reduce.default)(requestContext.getData(), function (acc, allAgreementId) {
    acc[allAgreementId] = true;
    return acc;
  }, {
    userType: authMethod
  });
  requestContext.setData(data);
}
function onSuccess(context) {
  context.service.getSession();
  context.dispatch('RESET_REQUIREMENTS');
}
function onFailed(context, _ref) {
  var error = _ref.error;
  context.dispatch('SET_REQUIREMENTS_STATUS', [error, false]);
}
var _default = function _default() {
  return {
    serviceName: 'resolveEula',
    config: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url: '/common/v2/requirements/eula'
    },
    beforeRequest: beforeRequest,
    onSuccess: onSuccess,
    onFailed: onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed
  };
};
var _default2 = exports.default = _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(beforeRequest, "beforeRequest", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-eula.js");
  reactHotLoader.register(onSuccess, "onSuccess", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-eula.js");
  reactHotLoader.register(onFailed, "onFailed", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-eula.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/service-configs/requirement-services/resolve-eula.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();