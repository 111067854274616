"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createServiceConfiguration = exports.createMockServiceConfiguration = void 0;
var _get = _interopRequireDefault(require("lodash/get"));
var _set = _interopRequireDefault(require("lodash/set"));
var _serviceConfigs = _interopRequireDefault(require("./service-configs"));
var _mock = _interopRequireDefault(require("./mock"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
var createServiceConfiguration = exports.createServiceConfiguration = function createServiceConfiguration(config) {
  return {
    global: (0, _get.default)(config, 'global', {}),
    serviceConfigs: (0, _serviceConfigs.default)(config)
  };
};
var createMockServiceConfiguration = exports.createMockServiceConfiguration = function createMockServiceConfiguration(config) {
  var services = {};
  if ((0, _get.default)(config, 'mock.enabled', false) || (0, _get.default)(config, 'mock.include', [].length > 0)) {
    (0, _set.default)(services, 'mock', (0, _mock.default)((0, _get.default)(config, 'mock', {})));
  }
  return services;
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(createServiceConfiguration, "createServiceConfiguration", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/index.js");
  reactHotLoader.register(createMockServiceConfiguration, "createMockServiceConfiguration", "/builds/audacious-inquiry/portfolio/web-platform/dashboard-portal-application/clone/src/services/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();